import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportFieldDirective } from 'src/app/core/directives/report-field.directive';
import { SanctionsComponent } from '../sanctions/sanctions.component';
import { PDFDocument } from 'src/app/core/interfaces/pdf-document.interface';
import { PdfTableService } from 'src/app/core/services/pdf-table.service';

interface SanctionEntity {
  row: string;
  result: string;
  reason: string;
}

interface SanctionDetail {
  authority: string;
  program: string;
  startDate: string;
  endDate: string;
}

interface TableOptions {
  headers: string[];
  columnWidths: number[];
  margins: { left: number; right: number };
}

@Component({
  selector: 'app-sanctions-summary',
  standalone: true,
  imports: [
    CommonModule,
    SanctionsComponent,
    ReportFieldDirective
  ],
  styles: [`
    :host {
      display: block;
      page-break-inside: avoid;
    }
    @media print {
      .page-break-avoid {
        page-break-inside: avoid;
      }
    }
  `],
  template: `
    <div class="flex w-full flex-wrap my-5">
      <div class="w-full mb-4">
      <div class="border border-grey border-t-cyan-700 w-full">
        <table class="w-full">
          <thead>
            <tr class="bg-blue-950">
              <th colspan="2" class="text-center">
                <h1 class="text-lg p-1 text-white m-0">Key Information</h1>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *reportField="'generalInfo.companyName'; let companyName">
              <td class="py-2 px-4 font-bold">Company Name:</td>
              <td class="py-2 px-4">{{ companyName }}</td>
            </tr>
            <tr *reportField="'generalInfo.monitoredEntities'; let monitoredEntities">
              <td class="py-2 px-4 font-bold">Monitored Entities:</td>
              <td class="py-2 px-4">{{ monitoredEntities.join(', ') }}</td>
            </tr>
            <tr *reportField="'generalInfo.vatNumber', let vatNumber">
              <td class="py-2 px-4 font-bold">VAT Number:</td>
              <td class="py-2 px-4">{{ vatNumber }}</td>
            </tr>
            <tr *reportField="'generalInfo.registrationNumber'; let registrationNumber">
                    <td class="py-2 px-4 font-bold">Registration Number:</td>
                    <td class="py-2 px-4">{{ registrationNumber }}
                    </td>
              </tr>
              <tr *reportField="'generalInfo.incorporationDate'; let incorporationDate">
                  <td class="py-2 px-4 font-bold">Incorporation Date:</td>
                  <td class="py-2 px-4">{{ incorporationDate |
                      date }}</td>
              </tr>
              <tr *reportField="'generalInfo.legalForm'; let legalForm">
                  <td class="py-2 px-4 font-bold">Legal Form:</td>
                  <td class="py-2 px-4">{{ legalForm }}</td>
              </tr>
              <tr *reportField="'generalInfo.taxCode'; let taxCode">
                  <td class="py-2 px-4 font-bold">Tax Code:</td>
                  <td class="py-2 px-4">{{ taxCode }}</td>
              </tr>
              <tr *reportField="'generalInfo.otherLegalName'; let value">
                  <td class="py-2 px-4 font-bold">Other Legal Name:</td>
                  <td class="py-2 px-4">{{
                    value
                      }}</td>
              </tr>
              <tr *reportField="'generalInfo.charterNumber'; let value">
                  <td class="py-2 px-4 font-bold">Charter Number:</td>
                  <td class="py-2 px-4">{{
                    value
                      }}</td>
              </tr>
              <tr *reportField="'generalInfo.establishmentDate'; let value">
                  <td class="py-2 px-4 font-bold">Establishment Date:</td>
                  <td class="py-2 px-4">{{
                    value
                      | date }}</td>
              </tr>
              <tr
                  *reportField="'generalInfo.companyType'; let value">
                  <td class="py-2 px-4 font-bold">Company Type:</td>
                  <td class="py-2 px-4">{{
                    value }}
                  </td>
              </tr>
              <tr *reportField="'generalInfo.sicDescription'; let value">
                  <td class="py-2 px-4 font-bold">SIC Description:</td>
                  <td class="py-2 px-4">{{
                    value
                      }}</td>
              </tr>
          </tbody>
        </table>
      </div>
      </div>
      <div class="w-full border-1 border-grey" *reportField="'sanctions.summary'; let sanctionsData">
          
      <div>
      <!-- Relations with Sanctioned Entities Section -->
      <div class="mb-8 border border-grey border-t-cyan-700 w-full">
        <div class="bg-blue-950 text-center text-white">
          <h1 class="text-lg p-1 text-white m-0">Sanctions Summary</h1>
        </div>
        <div class="flex text-center">
        <div class="bg-white border overflow-hidden m-2 w-1/2 border-t-cyan-700">
          <p class="text-sm text-gray-700 pt-[12px]"><span class="font-semibold">Sanctioned Entity:</span> {{ sanctionsData.sanctionedEntity }}</p>
        </div>
        <div class="bg-white border overflow-hidden m-2 w-1/2 border-t-cyan-700">
          <p class="text-sm text-gray-700 pt-[12px]"><span class="font-semibold">Key Points:</span> {{ sanctionsData.keyPoints }}</p>
        </div>
        </div>
        <div class="bg-bgColor text-center">
          <h3 class="w-full m-0 p-2 text-xl">Relations with Sanctioned Hostile and/or Malevolent Entities</h3>
        </div>
        <div class="bg-white shadow overflow-hidden">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Entity</th>
                <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Result</th>
                <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Reason</th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr *ngFor="let entity of sanctionsData.relationsWithSanctionedHostileAndOrMalevolentEntities">
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{{ entity.row }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ entity.result }}</td>
                <td class="px-6 py-4 text-sm text-gray-500">{{ entity.reason }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Sanction Details Section -->
      <div class="mb-8 border border-grey border-t-cyan-700 w-full">
        <div class="bg-blue-950 text-white text-center">
          <h1 class="text-lg p-1 text-white m-0">Sanction Details</h1>
        </div>
        <div class="bg-white shadow overflow-hidden ">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Authority</th>
                <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Program</th>
                <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Start Date</th>
                <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">End Date</th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr *ngFor="let detail of sanctionsData.sanctionDetails">
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ detail.authority }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ detail.program }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ detail.startDate }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ detail.endDate }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
      </div>
    </div>
  `
})
export class SanctionsSummaryComponent {
  @Input() printMode: boolean = false;

  constructor(private pdfTableService: PdfTableService) {}

  static renderToPdf(doc: PDFDocument, data: any, dueDiligence: any): void {
    const pageWidth = 612; // US Letter width in points
    const marginLeft = 50;
    const marginRight = 50;
    const contentWidth = pageWidth - marginLeft - marginRight;
    
    // Ensure currentY is a valid number
    let currentY = doc.y;
    
    // Key Information Section
    doc.rect(marginLeft, currentY, contentWidth, 30)
       .fill('#172554'); // bg-blue-950
    
    doc.fontSize(14)
       .font('Helvetica-Bold')
       .fill('#ffffff')
       .text('Key Information', marginLeft, currentY + 8, { 
         align: 'center',
         width: contentWidth
       });

    doc.fill('#000000');
    currentY += 40;

    const keyInfoHeaders = ['Field', 'Value'];
    const keyInfoRows = [
      ['Company Name', data.generalInfo?.companyName || ''],
      ['Monitored Entities', data.generalInfo?.monitoredEntities?.join(', ') || ''],
      ['VAT Number', data.generalInfo?.vatNumber || ''],
      ['Registration Number', data.generalInfo?.registrationNumber || ''],
      ['Incorporation Date', data.generalInfo?.incorporationDate || ''],
      ['Legal Form', data.generalInfo?.legalForm || ''],
      ['Tax Code', data.generalInfo?.taxCode || ''],
      ['Other Legal Name', data.generalInfo?.otherLegalName || ''],
      ['Charter Number', data.generalInfo?.charterNumber || ''],
      ['Establishment Date', data.generalInfo?.establishmentDate || ''],
      ['Company Type', data.generalInfo?.companyType || ''],
    ].filter(row => row[1]);

    if (keyInfoRows.length > 0) {
      currentY = PdfTableService.drawTable(doc, keyInfoHeaders, keyInfoRows, currentY);
    }

    // Add SIC Description after the table with dynamic height calculation
    const sicDescription = data.generalInfo?.sicDescription || '';
    if (sicDescription) {
      // Calculate text height for SIC Description
      const textOptions = {
        width: contentWidth - 20, // Account for padding
        lineBreak: true
      };
      
      const sicDescriptionHeight = doc.heightOfString(sicDescription, textOptions);
      const boxHeight = Math.max(sicDescriptionHeight + 30, 40);

      // Draw box for SIC Description
      doc.rect(marginLeft, currentY, contentWidth, boxHeight)
         .fill('#ffffff');
      doc.lineWidth(1)
         .rect(marginLeft, currentY, contentWidth, boxHeight)
         .stroke();

      doc.fontSize(10)
         .font('Helvetica-Bold')
         .fill('#000000')
         .text('SIC Description:', marginLeft + 10, currentY + 5);
      doc.font('Helvetica')
         .text(sicDescription, marginLeft + 10, currentY + 20, textOptions);

      doc.addPage();

      currentY = 50;
    }

    // Sanctions Summary Section
    if (data.sanctions?.summary) {
      // Sanctions Summary Header
      doc.rect(marginLeft, currentY, contentWidth, 30)
         .fill('#172554');
      
      doc.fontSize(14)
         .font('Helvetica-Bold')
         .fill('#ffffff')
         .text('Sanctions Summary', marginLeft, currentY + 8, { 
           align: 'center',
           width: contentWidth
         });
      
      currentY += 40;

      // Sanctioned Entity and Key Points in a two-column layout
      const columnWidth = (contentWidth - 10) / 2; // 10px gap between columns
      
      // Define padding and minimum height constants
      const padding = 10;
      const minHeight = 40;
      
      // Calculate text heights
      const sanctionedEntityText = data.sanctions.summary.sanctionedEntity || '';
      const keyPointsText = data.sanctions.summary.keyPoints || '';
      
      // Set text options for measurement
      const textOptions = {
        width: (columnWidth) - (padding * 2),
        lineBreak: false
      };
      
      // Measure text heights
      const sanctionedEntityHeight = doc.heightOfString(sanctionedEntityText, textOptions);
      const keyPointsHeight = doc.heightOfString(keyPointsText, textOptions) / 2;
      
      // Use the larger height for both boxes with proper padding
      const boxHeight = Math.max(Math.max(sanctionedEntityHeight, keyPointsHeight) + (padding * 2), minHeight);

      // Left column - Sanctioned Entity
      doc.rect(marginLeft, currentY, columnWidth, boxHeight)
         .fill('#ffffff');
      doc.lineWidth(1)
         .rect(marginLeft, currentY, columnWidth, boxHeight)
         .stroke();

      doc.fontSize(10)
         .font('Helvetica-Bold')
         .fill('#000000')
         .text('Sanctioned Entity:', marginLeft + 10, currentY + 5);
      doc.font('Helvetica')
         .text(sanctionedEntityText, marginLeft + 10, currentY + 20, textOptions);
      
      // Right column - Key Points
      doc.rect(marginLeft + columnWidth + 10, currentY, columnWidth, boxHeight)
         .fill('#ffffff');
      doc.lineWidth(1)
         .rect(marginLeft + columnWidth + 10, currentY, columnWidth, boxHeight)
         .stroke();

      doc.fontSize(10)
         .font('Helvetica-Bold')
         .fill('#000000')
         .text('Key Points:', marginLeft + columnWidth + 20, currentY + 5);
      doc.font('Helvetica')
         .text(keyPointsText, marginLeft + columnWidth + 20, currentY + 20, textOptions);
      
      currentY += boxHeight + 10; // Adjust the Y position based on the dynamic height

      // Relations with Sanctioned Entities Section
      const pageHeight = 792; // US Letter height in points
      const remainingSpace = pageHeight - currentY;
      const percentageRemaining = (remainingSpace / pageHeight) * 100;
      
      if (percentageRemaining < 50) {
        doc.addPage();
        currentY = 50; // Reset Y position after page break
      }
      
      doc.rect(marginLeft, currentY, contentWidth, 30)
         .fill('#172554'); // bg-gray-200
      
      doc.fontSize(12)
         .font('Helvetica-Bold')
         .fill('#ffffff')
         .text('Relations with Sanctioned Hostile and/or Malevolent Entities', marginLeft, currentY + 8, { 
           align: 'center',
           width: contentWidth
         })
         .fill('#000000');
      
      currentY += 40;

      if (data.sanctions.summary.relationsWithSanctionedHostileAndOrMalevolentEntities?.length > 0) {
        const relationsHeaders = ['Entity', 'Result', 'Reason'];
        const relationsRows = data.sanctions.summary.relationsWithSanctionedHostileAndOrMalevolentEntities.map(
          (entity: SanctionEntity) => [
            entity.row || '',
            entity.result || '',
            entity.reason || ''
          ]
        );

        currentY = PdfTableService.drawTable(doc, relationsHeaders, relationsRows, currentY);
      }

      currentY += 30;

      // Sanction Details Section
      // Add a page break before Sanction Details
      doc.addPage();
      currentY = 50; // Reset Y position to top margin

      doc.rect(marginLeft, currentY, contentWidth, 30)
         .fill('#172554');
      
      doc.fontSize(14)
         .font('Helvetica-Bold')
         .fill('#ffffff')
         .text('Sanction Details', marginLeft, currentY + 8, { 
           align: 'center',
           width: contentWidth
         });
      
      currentY += 40;

      // Reset text color to black for the table content
      doc.fill('#000000');

      if (data.sanctions.summary.sanctionDetails?.length > 0) {
        const detailsHeaders = ['Authority', 'Program', 'Start Date', 'End Date'];
        const detailsRows = data.sanctions.summary.sanctionDetails.map(
          (detail: SanctionDetail) => [
            detail.authority || '',
            detail.program || '',
            detail.startDate || '',
            detail.endDate || ''
          ]
        );

        currentY = PdfTableService.drawTable(doc, detailsHeaders, detailsRows, currentY);
      }
    }
    doc.y = currentY;
  }
}