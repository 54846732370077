import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';

// Declare global $zoho variable
declare global {
  interface Window {
    $zoho: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ZohoSalesIQService {
  private loaded = false;
  private readyCallbacks: Function[] = [];
  private widgetReady = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  /**
   * Initialize and load the Zoho SalesIQ chat widget
   */
  initializeZohoChat(): void {
    // Only run in browser environment, not during SSR
    if (isPlatformBrowser(this.platformId) && !this.loaded) {
      this.loadZohoScript();
    }
  }

  /**
   * Load the Zoho SalesIQ script exactly as provided by Zoho
   */
  private loadZohoScript(): void {
    this.loaded = true;

    // First script - initialization
    const scriptInit = document.createElement('script');
    scriptInit.type = 'text/javascript';
    scriptInit.text = `window.$zoho=window.$zoho || {};$zoho.salesiq=$zoho.salesiq||{ready:function(){}}`;
    document.head.appendChild(scriptInit);

    // Setup ready function to handle our callbacks
    const originalReady = window.$zoho.salesiq.ready;
    window.$zoho.salesiq.ready = () => {
      this.widgetReady = true;
      
      // Call the original ready function if it exists
      if (typeof originalReady === 'function') {
        originalReady();
      }
      
      // Process our callbacks
      this.processCallbacks();
    };

    // Second script - widget
    const scriptWidget = document.createElement('script');
    scriptWidget.type = 'text/javascript';
    scriptWidget.id = 'zsiqscript';
    scriptWidget.defer = true;
    scriptWidget.src = `https://salesiq.zohopublic.com/widget?wc=${environment.zohoSalesIQ.widgetCode}`;
    document.head.appendChild(scriptWidget);
    
    // Add widget container div
    const widgetContainer = document.createElement('div');
    widgetContainer.id = 'zsiqwidget';
    document.body.appendChild(widgetContainer);
  }

  /**
   * Handle the ready callback and process queued callbacks
   */
  private handleReady(): void {
    this.widgetReady = true;
    this.processCallbacks();
  }

  /**
   * Process all queued callbacks
   */
  private processCallbacks(): void {
    while (this.readyCallbacks.length > 0) {
      const callback = this.readyCallbacks.shift();
      if (callback) {
        callback();
      }
    }
  }

  /**
   * Execute a function when the widget is ready
   */
  private whenReady(callback: Function): void {
    if (this.widgetReady) {
      callback();
    } else {
      this.readyCallbacks.push(callback);
    }
  }

  /**
   * Hide the Zoho SalesIQ chat widget
   */
  hideZohoChat(): void {
    if (isPlatformBrowser(this.platformId) && window.$zoho && window.$zoho.salesiq) {
      this.whenReady(() => {
        if (window.$zoho.salesiq.floatbutton) {
          window.$zoho.salesiq.floatbutton.visible(false);
        }
      });
    }
  }

  /**
   * Show the Zoho SalesIQ chat widget
   */
  showZohoChat(): void {
    if (isPlatformBrowser(this.platformId) && window.$zoho && window.$zoho.salesiq) {
      this.whenReady(() => {
        if (window.$zoho.salesiq.floatbutton) {
          window.$zoho.salesiq.floatbutton.visible(true);
        }
      });
    }
  }

  /**
   * Set visitor information for better tracking
   * @param name Visitor name
   * @param email Visitor email
   */
  setVisitorInfo(name: string, email: string): void {
    if (isPlatformBrowser(this.platformId) && window.$zoho && window.$zoho.salesiq) {
      this.whenReady(() => {
        if (window.$zoho.salesiq.visitor) {
          window.$zoho.salesiq.visitor.name(name);
          window.$zoho.salesiq.visitor.email(email);
        }
      });
    }
  }
} 