import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { throwError, Observable, from, of, switchMap } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SubmitParams, GetDiligenceParams, PostDiligenceParams, PutDiligenceParams } from '../models/params';
import { ThreeDsecureResponse } from '../models/payment';
import { AuthService } from '@auth0/auth0-angular'; // Import AuthService
import { CreateCreditDto, Credit, AdjustCreditDto } from '../models/credit';
import { Organization, CreateOrganizationDto, UpdateOrganizationDto } from '../models/organization';

export interface DiligenceQueryParams {
  page: number;
  limit: number;
  sort: {
    field: string;
    order: 'asc' | 'desc';
  };
  filters: {
    phase?: number[];
    user_email?: string[];
    report?: string;
    entityType?: string;
    organization_id?: string;
    from_date?: string;
    to_date?: string;
  };
  search?: string;
}

interface DiligenceResponse {
  data: any[];
  metadata: {
    total: number;
    page: number;
    totalPages: number;
    limit: number;
  };
}

interface FilterOptionsResponse {
  users: string[];
  organizations: { id: string; name: string; }[];
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    public http: HttpClient,
    private auth: AuthService
  ) { }

  private getAuthHeaders(): Observable<HttpHeaders> {
    return from(this.auth.getAccessTokenSilently()).pipe(
      mergeMap(token => {
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
        return of(headers);
      })
    );
  }

  getCompanies(httParams: SubmitParams, page: number, pageSize: number) {
    const params = new HttpParams()
      .set('country', httParams.country)
      .set('query', httParams.query)
      .set('city', httParams.city as string)
      .set('page', page)
      .set('pageSize', pageSize)
      .set('province', httParams.province as string);

    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.get<any>(`${environment.baseUrl}getCompanies`, { params: params, headers: headers }))
    );
  }

  getIndividuals(httParams: SubmitParams, page: number, pageSize: number) {
    const params = new HttpParams()
      .set('country', httParams.country)
      .set('query', httParams.query)
      .set('dateOfBirth', httParams.dateOfBirth as string)
      .set('pageSize', pageSize)
      .set('page', page);

    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.get<any>(`${environment.baseUrl}getIndividuals`, { params: params, headers: headers }))
    );
  }

  getDiligencesByPhase(queryParams: DiligenceQueryParams = {
    page: 1,
    limit: 20,
    sort: {
      field: 'updated_at',
      order: 'desc'
    },
    filters: {}
  }) {
    let params = new HttpParams();
    
    // Add pagination
    if (queryParams.page) params = params.set('page', queryParams.page.toString());
    if (queryParams.limit) params = params.set('limit', queryParams.limit.toString());
    
    // Add sorting
    if (queryParams.sort) {
      params = params.set('sort[field]', queryParams.sort.field);
      params = params.set('sort[order]', queryParams.sort.order);
    }
    
    // Add filters
    if (queryParams.filters) {
      const { phase, user_email, report, entityType, organization_id, from_date, to_date } = queryParams.filters;
      if (phase) {
        phase.forEach(p => {
          params = params.append('filters[phase][]', p.toString());
        });
      }
      if (user_email) {
        user_email.forEach(email => {
          params = params.append('filters[user_email][]', email);
        });
      }
      if (report) params = params.set('filters[report]', report);
      if (entityType) params = params.set('filters[entityType]', entityType);
      if (organization_id) params = params.set('filters[organization_id]', organization_id);
      if (from_date) params = params.set('filters[from_date]', from_date);
      if (to_date) params = params.set('filters[to_date]', to_date);
    }
    
    // Add search
    if (queryParams.search) params = params.set('search', queryParams.search);

    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.get<DiligenceResponse>(`${environment.baseUrl}reports/query`, 
        { params, headers }))
    );
  }

  getDiligencesByEmail() {

    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.get<any>(`${environment.baseUrl}reports/user`, { headers: headers }))
    );
  }

  getDiligenceById(id: string) {
    
    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.get<any>(`${environment.baseUrl}reports/${id}`, { headers: headers }))
    );
  }

  postDiligence(httParams: PostDiligenceParams) {
    const body = {
      companies: httParams.companies,
      packages: httParams.packages,
      ubo: httParams.ubo
    };

    return this.getAuthHeaders().pipe(
      mergeMap(headers => 
        this.http.post<any>(`${environment.baseUrl}reports`, body, { headers: headers }).pipe(
          catchError(error => {
            console.error('API error:', error);
            return throwError(error);
          })
        )
      )
    );
  }

  // ADMIN 

  putDiligence(httParams: PutDiligenceParams) {
    const params = new HttpParams().set('uuid', httParams.uuid);

    const body = {
      "phase": httParams.phase,
      "repScore": httParams.repScore,
      "repSumm": httParams.repSumm,
      "critIssueSummary": httParams.critIssueSummary,
      "previousDiligencePhase": httParams.previousDiligencePhase,
      "critIssueDetails": httParams.critIssueDetails,
      "sancIssueSummary": httParams.sancIssueSummary,
      "email": httParams.userEmail
    };

    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.put<any>(`${environment.baseUrl}reports/${httParams.uuid}`, body, { params: params, headers: headers }))
    );
  }

  getUsers() {
    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.get<any>(`${environment.baseUrl}users`, { headers: headers }))
    );
  }

  getUsersReport() {
    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.get<any>(`${environment.baseUrl}reports/users`, { headers: headers }))
    );
  }

  postSale(httParams: any): Observable<any> {
    const threeDSecureEndpoint = environment.cardKnoxApiEndpoint;
    return this.http.post<ThreeDsecureResponse>(threeDSecureEndpoint, {}).pipe(
      switchMap((ck3DS: ThreeDsecureResponse) => {
        const { paymentDetails } = httParams; // Destructure to remove xAmount
        const body = {
          paymentDetails: {
            "xCommand": "cc:sale",
            "xAllowDuplicate": "TRUE", 
            "xSoftwareName": 'name', // da config
            "xSoftwareVersion": "1.0.0",
            "xVersion": "4.5.9", // da config

            // dati specifici della chiamata da form
            "xCVV": paymentDetails.xCVV,
            "xExp": paymentDetails.xExp,
            "xCardNum": paymentDetails.xCardNum,
            "xEmail": paymentDetails.xEmail,
            "xCustReceipt": true,
            
            //dati specifici di 3ds security
            "x3dsReferenceId": ck3DS.refnum,
            "x3dsInitializeStatus": ck3DS.status,

            //dati aggiuntivi obbligatori per 3ds security
            "xBillFirstName": paymentDetails.xBillFirstName,
            "xBillLastName": paymentDetails.xBillLastName,
            "xBillStreet": paymentDetails.xBillStreet,
            "xBillCity": paymentDetails.xBillCity,
            "xBillState": paymentDetails.xBillState,
            "xBillZip": paymentDetails.xBillZip,
            "xBillMobile": paymentDetails.xBillMobile,
          },
          packages: httParams.packages,
          companies: httParams.companies,
          uboSelected: httParams.uboSelected
        };
        return this.getAuthHeaders().pipe(
          mergeMap(headers => this.http.post<any>(`${environment.baseUrl}sale`, body, { headers: headers }))
        );
      })
    );
  }

  listCredits(): Observable<Credit[]> {
    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.get<Credit[]>(`${environment.baseUrl}credits`, { headers }))
    );
  }

  listCreditsForEntity(entityType: string, entityId: string) {
    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.get<[Credit]>(`${environment.baseUrl}credits/${entityType}/${entityId}`, { headers: headers }))
    );
  }

  addCredit(credit: CreateCreditDto) {
    const body = {
      amount: credit.amount,
      entityType: credit.entityType,
      entityId: credit.entityId,
      startDate: credit.startDate,
      endDate: credit.endDate
    };
    
    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.post<any>(`${environment.baseUrl}credits/add`, body, { headers: headers }))
    );
  }


  adjustCredit(adjustment: AdjustCreditDto) {
    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.post<any>(
        `${environment.baseUrl}credits/${adjustment.id}/adjust`, 
        { amount: adjustment.amount },
        { headers: headers }
      ))
    );
  }

  getOrganizations() {
    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.get<any>(`${environment.baseUrl}organizations`, { headers: headers }))
    );
  }

  // Organization CRUD operations
  getOrganizationById(id: string) {
    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.get<Organization>(`${environment.baseUrl}organizations/${id}`, { headers }))
    );
  }

  createOrganization(data: CreateOrganizationDto) {
    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.post<Organization>(`${environment.baseUrl}organizations`, data, { headers }))
    );
  }

  updateOrganization(id: string, data: UpdateOrganizationDto) {
    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.put<Organization>(`${environment.baseUrl}organizations/${id}`, data, { headers }))
    );
  }

  deleteOrganization(id: string) {
    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.delete<void>(`${environment.baseUrl}organizations/${id}`, { headers }))
    );
  }

  addOrganizationMember(orgId: string, email: string) {
    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.post<void>(`${environment.baseUrl}organizations/${orgId}/members`, { email }, { headers }))
    );
  }

  removeOrganizationMember(orgId: string, email: string) {
    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.delete<void>(`${environment.baseUrl}organizations/${orgId}/members/${email}`, { headers }))
    );
  }

  getFilterOptions(): Observable<FilterOptionsResponse> {
    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.get<FilterOptionsResponse>(`${environment.baseUrl}reports/filter-options`, { headers }))
    );
  }

}