export const environment = {
    baseUrl: 'https://tevunanode-test.onrender.com/tevunaApi/', //basic plan
    auth: {
        domain: 'dev-bgsrp2l8d53fhhda.us.auth0.com',
        clientId: 'Hf6hISXOvaa0xnTuHtnY5X5J7Lm5bELF',
        authorizationParams: {
            redirect_uri: window.location.origin,
            audience: 'backend'
          }
    },
    environment: 'staging',
    cardKnoxApiEndpoint: 'https://api.cardknox.com/threeds/v1/jwt/production',
    zohoSalesIQ: {
        widgetSrc: 'https://salesiq.zohopublic.com/widget',
        widgetCode: 'siqddd27561bf722aaa50bad75757bf3eca77d1ca538c7a430317e76743331b9e60'
    }
};