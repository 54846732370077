import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy,  Component, Input, OnInit, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { MyAuthService } from 'src/app/core/services/auth.service';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatButtonModule,  MatMenuModule, MatIconModule, RouterModule]
})
export class NavbarComponent implements OnInit {
  auth = inject(AuthService);
  route = inject(Router);
  myAuthService = inject(MyAuthService)

  isAdmin$: Observable<boolean> | undefined;
  isSales$: Observable<boolean> | undefined;

  ngOnInit(): void {
    this.isAdmin$ = this.myAuthService.isAdmin()
    this.isSales$ = this.myAuthService.isSales()
  }

  navigateToMyAccount() {
    this.route.navigate(['myaccount']);
  }

  navigateToAdmin() {
    this.route.navigate(['admin']);
  }

  navigateToHome() {
    this.route.navigate(['']);
  }
}
