import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MyAuthService } from 'src/app/core/services/auth.service';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(private authService: MyAuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    
    // Get roles from route data or default to admin if not specified
    const allowedRoles = next.data?.['allowedRoles'] as string[] || ['admin'];
    
    return this.authService.hasAnyRole(allowedRoles).pipe(
      take(1),
      map(hasRole => {
        if (hasRole) {
          return true;
        } else {
          return this.router.createUrlTree(['/']);
        }
      })
    );
  }
}