import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { ApiService } from '../../../core/services/api.service';
import { Credit, CreateCreditDto, AdjustCreditDto } from '../../../core/models/credit';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { animate, state, style, transition, trigger } from '@angular/animations';

// Interface for credit adjustment data
interface CreditWithAdjustment extends Credit {
  showAdjustmentRow?: boolean;
  adjustmentAmount?: number;
  adjustmentError?: string;
}

@Component({
  selector: 'app-credits',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    MatButtonModule,
    MatTableModule,
    MatCardModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatIconModule
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  template: `
    <div class="credits-container flex flex-col">
      <ng-container *ngIf="credits.length > 0">
        <div class="credit-list">
          <div *ngFor="let credit of credits" class="mb-4 rounded-md">
            <!-- Credit row -->
            <div class="grid grid-cols-4 gap-4 p-4">
              <div>
                <div class="text-sm text-gray-500">Remaining Amount</div>
                <div>{{ credit.remaining_amount }}</div>
              </div>
              <div>
                <div class="text-sm text-gray-500">Start Date</div>
                <div>{{ credit.startDate | date }}</div>
              </div>
              <div>
                <div class="text-sm text-gray-500">End Date</div>
                <div>{{ credit.endDate | date }}</div>
              </div>
              <div class="flex items-center justify-end">
                <button mat-button color="primary" (click)="toggleAdjustmentRow(credit)">
                  {{ credit.showAdjustmentRow ? 'Cancel' : 'Adjust' }}
                </button>
              </div>
            </div>
            
            <!-- Adjustment row -->
            <div *ngIf="credit.showAdjustmentRow" class="p-4 bg-gray-50 flex gap-4 items-center border-t">
              <mat-form-field appearance="outline">
                <mat-label>Deduction Amount</mat-label>
                <input matInput type="number" [(ngModel)]="credit.adjustmentAmount" 
                      placeholder="Enter amount to deduct" min="0" [max]="credit.remaining_amount"
                      (input)="validateAdjustment(credit)">
                <mat-error *ngIf="credit.adjustmentError">{{ credit.adjustmentError }}</mat-error>
              </mat-form-field>
              <button mat-raised-button color="primary" 
                      [disabled]="!isValidAdjustment(credit)"
                      (click)="applyAdjustment(credit)">
                Apply Deduction
              </button>
            </div>
          </div>
        </div>

        <mat-divider class="my-6"></mat-divider>
      </ng-container>

      <div class="flex flex-col space-y-4 p-4 border rounded-md bg-gray-50 mt-auto">
        <div class="text-4xl font-semibold my-4">Add Credit</div>
        <form [formGroup]="creditForm" (ngSubmit)="addCredit()" >
          <div class="flex space-x-4">
            <mat-form-field appearance="outline" class="flex-1">
              <mat-label>Amount</mat-label>
              <input matInput type="number" formControlName="amount" placeholder="Amount" required>
              <mat-error *ngIf="creditForm.get('amount')?.hasError('required')">Amount is required</mat-error>
              <mat-error *ngIf="creditForm.get('amount')?.hasError('min')">Amount must be positive</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="flex-1">
              <mat-label>Start Date</mat-label>
              <input matInput [matDatepicker]="addStartPicker" formControlName="startDate" placeholder="Start Date">
              <mat-datepicker-toggle matSuffix [for]="addStartPicker"></mat-datepicker-toggle>
              <mat-datepicker #addStartPicker></mat-datepicker>
              <mat-error *ngIf="creditForm.get('startDate')?.hasError('required')">Start date is required</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="flex-1">
              <mat-label>End Date</mat-label>
              <input matInput [matDatepicker]="addEndPicker" formControlName="endDate" placeholder="End Date">
              <mat-datepicker-toggle matSuffix [for]="addEndPicker"></mat-datepicker-toggle>
              <mat-datepicker #addEndPicker></mat-datepicker>
              <mat-error *ngIf="creditForm.get('endDate')?.hasError('required')">End date is required</mat-error>
            </mat-form-field>
          </div>

          <button mat-raised-button color="primary" type="submit" [disabled]="!creditForm.valid">
            Add Credit
          </button>
        </form>
      </div>
    </div>
  `,
})
export class CreditsComponent {
  @Input() entityType!: 'user' | 'organization';
  @Input() entityId!: string;

  creditForm: FormGroup;
  credits: CreditWithAdjustment[] = [];
  displayedColumns: string[] = ['amount', 'startDate', 'endDate', 'actions'];

  constructor(
    private apiService: ApiService,
    private fb: FormBuilder
  ) {
    this.creditForm = this.fb.group({
      amount: ['', [Validators.required, Validators.min(0)]],
      startDate: [''],
      endDate: ['']
    });
  }

  ngOnInit() {
    this.loadCredits();
  }

  addCredit() {
    if (this.creditForm.valid) {
      const creditData: CreateCreditDto = {
        amount: this.creditForm.value.amount,
        entityType: this.entityType,
        entityId: String(this.entityId),
        ...(this.creditForm.value.startDate && { startDate: this.creditForm.value.startDate }),
        ...(this.creditForm.value.endDate && { endDate: this.creditForm.value.endDate })
      };

      this.apiService.addCredit(creditData).subscribe(() => {
        this.loadCredits();
        this.creditForm.reset();
      });
    }
  }

  loadCredits() {
    this.apiService.listCreditsForEntity(this.entityType, this.entityId).subscribe({
      next: (credits) => {
        this.credits = credits.map(credit => ({
          ...credit,
          showAdjustmentRow: false,
          adjustmentAmount: 0
        }));
      },
      error: (error) => {
        console.error('Error loading credits:', error);
      }
    });
  }

  toggleAdjustmentRow(credit: CreditWithAdjustment) {
    credit.showAdjustmentRow = !credit.showAdjustmentRow;

    if (credit.showAdjustmentRow) {
      credit.adjustmentAmount = 0;
      credit.adjustmentError = '';
    }
  }

  validateAdjustment(credit: CreditWithAdjustment) {
    if (!credit.adjustmentAmount || credit.adjustmentAmount <= 0) {
      credit.adjustmentError = 'Deduction must be positive';
      return false;
    }

    if (credit.adjustmentAmount > credit.remaining_amount) {
      credit.adjustmentError = `Cannot deduct more than available (${credit.remaining_amount})`;
      return false;
    }

    credit.adjustmentError = '';
    return true;
  }

  isValidAdjustment(credit: CreditWithAdjustment): boolean {
    return !!credit.adjustmentAmount &&
      credit.adjustmentAmount > 0 &&
      credit.adjustmentAmount <= credit.remaining_amount &&
      !credit.adjustmentError;
  }

  applyAdjustment(credit: CreditWithAdjustment) {
    if (!this.isValidAdjustment(credit)) {
      return;
    }

    // Negative adjustment
    const adjustmentData: AdjustCreditDto = {
      id: credit.id,
      amount: -1 * Math.abs(credit.adjustmentAmount || 0) // Ensure it's negative
    };

    this.apiService.adjustCredit(adjustmentData).subscribe({
      next: () => {
        this.loadCredits();
      },
      error: (error: unknown) => {
        console.error('Error applying adjustment:', error);
        credit.adjustmentError = 'Failed to apply adjustment';
      }
    });
  }

  isExpanded = (index: number, credit: CreditWithAdjustment) => credit.showAdjustmentRow;
} 