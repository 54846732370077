import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreditDialogComponent } from '../../shared/components/credits-dialog/credits-dialog.component';
import { ApiService } from '../../core/services/api.service';
import { MatTableModule } from '@angular/material/table';
import { Credit } from '../../core/models/credit';
import { PageEvent } from '@angular/material/paginator';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NavbarComponent } from '../../shared/components/navbar/navbar.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { OrganizationDialogComponent } from '../../shared/components/organization-dialog/organization-dialog.component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { EmailCreditsDialogComponent } from '../../shared/components/email-credits-dialog/email-credits-dialog.component';


interface User {
    id: number;
    name: string;
    email: string;
    reportsGenerated: number;
    moneySpent: number;
    creditsLeft: number;
    credits: Credit[];
    organizations?: string;
}

interface UserResponse {
  start: number;
  limit: number;
  length: number;
  users: User[];
  total: number;
}

interface Organization {
    id: number;
    name: string;
    creditsLeft: number;
    credits: Credit[];
}

@Component({
  selector: 'app-accounts',
  templateUrl: './account.component.html',
  imports: [
    MatTableModule, 
    MatPaginatorModule, 
    NavbarComponent, 
    MatTabsModule, 
    CommonModule, 
    MatIconModule, 
    MatButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule
  ],
  standalone: true
})
export class AccountsComponent implements OnInit {
  users: User[] = [];
  organizations: Organization[] = [];
  displayedColumns: string[] = ['user', 'reportsGenerated', 'actions'];
  displayedColumnsOrg: string[] = ['name'];
  pageSize = 10;
  pageIndex = 0;
  totalUsers = 0;
  totalOrganizations = 0;
  activeTab = 'users';
  emailForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  });

  constructor(private apiService: ApiService, private dialog: MatDialog, private router: Router) {}

  ngOnInit() {
    this.loadUsers();
  }

  onTabChange(event: MatTabChangeEvent) {
    this.pageIndex = 0;
    this.activeTab = event.index === 0 ? 'users' : 'organizations';
    this.loadData();
  }

  loadData() {
    if (this.activeTab === 'users') {
      this.loadUsers();
    } else {
      this.loadOrganizations();
    }
  }

  loadOrganizations() {
    this.apiService.getOrganizations().subscribe((response: any) => {
      this.organizations = response;
    });
  }

  loadUsers() {
    this.apiService.getUsersReport().subscribe((response: UserResponse) => {
      this.users = response.users;
      this.totalUsers = response.total;
    });
  }

  handlePageEvent(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadData();
  }

  openCreditDialog(user: User) {
    const dialogRef = this.dialog.open(CreditDialogComponent, {
      width: '1000px',
      height: '500px',
      data: { 
        entity: user,
        entityType: 'user'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadData();
      }
    });
  }

  viewOrganization(org: Organization) {
    this.router.navigate(['/organizations', org.id]);
  }

  createOrganization() {
    const dialogRef = this.dialog.open(OrganizationDialogComponent, {
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.apiService.createOrganization(result).subscribe({
          next: () => {
            this.loadOrganizations();
          },
          error: (error) => {
            console.error('Error creating organization:', error);
          }
        });
      }
    });
  }

  openEmailCreditsDialog() {
    const emailDialogRef = this.dialog.open(EmailCreditsDialogComponent, {
      width: '400px'
    });

    emailDialogRef.afterClosed().subscribe(email => {
      if (email) {
        const creditDialogRef = this.dialog.open(CreditDialogComponent, {
          width: '1000px',
          height: '500px',
          data: { 
            entity: {
              email: email,
              credits: []
            },
            entityType: 'user'
          }
        });

        creditDialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.loadData();
          }
        });
      }
    });
  }
}