import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, Event } from '@angular/router';
import { GoogleAnalyticsService } from './core/services/google-analytics.service';
import { filter } from 'rxjs/operators';
import { AuthService } from '@auth0/auth0-angular';
import { ZohoSalesIQService } from './core/services/zoho-salesiq.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Tevuna';
  constructor(
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    private authService: AuthService,
    private zohoService: ZohoSalesIQService
  ) {
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.googleAnalyticsService.pageView(event.urlAfterRedirects);
    });
  }
  
  ngOnInit(): void {
    // Always initialize Zoho SalesIQ chat widget for all users
    this.zohoService.initializeZohoChat();
    
    // Set user information in Zoho only when authenticated
    this.authService.user$.subscribe(user => {
      if (user && user.email) {
        this.zohoService.setVisitorInfo(user.name || user.email, user.email);
      }
    });
  }
}
