<div class="min-h-full">
    <app-navbar></app-navbar>
    <div class="m-5 p-5">
        <div class="flex w-full items-center p-1 px-4 mb-5 border-b border-gray-200">
            <h1 class="text-2xl font-bold">Reports Dashboard</h1>
        </div>

        <mat-tab-group [selectedIndex]="selectedTab" (selectedIndexChange)="onTabChange($event)" class="mb-6">
            <mat-tab *ngFor="let tab of tabs">
                <ng-template mat-tab-label>
                    <div class="flex items-center gap-2">
                        <mat-icon [class]="tab.color">{{tab.icon}}</mat-icon>
                        <span>{{tab.label}}</span>
                    </div>
                </ng-template>

                <!-- Filters Section -->
                <div class="flex flex-wrap gap-4 mt-6 mb-6">
                    <!-- Email Filter -->
                    <mat-form-field class="w-96">
                        <mat-label>User Email</mat-label>
                        <mat-chip-grid #chipGrid>
                            <mat-chip-row *ngFor="let email of selectedEmails"
                                        (removed)="removeEmail(email)">
                                {{email}}
                                <button matChipRemove>
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </mat-chip-row>
                        </mat-chip-grid>
                        <input placeholder="User email..."
                               #emailInput
                               [formControl]="emailCtrl"
                               [matChipInputFor]="chipGrid"
                               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                               (matChipInputTokenEnd)="addEmail($event)"
                               [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete"
                                        (optionSelected)="selected($event)">
                            <mat-option *ngFor="let email of filteredEmails | async" [value]="email">
                                {{email}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <!-- Target Type Filter -->
                    <mat-form-field class="w-48">
                        <mat-label>Target Type</mat-label>
                        <mat-select [(ngModel)]="selectedEntityType" (ngModelChange)="onEntityTypeChange($event)">
                            <mat-option [value]="null">All</mat-option>
                            <mat-option *ngFor="let type of entityTypes" [value]="type">
                                {{type}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!-- Target Search -->
                    <mat-form-field class="w-64">
                        <mat-label>Target</mat-label>
                        <input matInput [(ngModel)]="searchTerm" (input)="onSearch($event)" placeholder="Search target name...">
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>

                    <!-- Package Filter -->
                    <mat-form-field class="w-64">
                        <mat-label>Package Type</mat-label>
                        <mat-select [(ngModel)]="selectedPackage" (ngModelChange)="onPackageChange($event)">
                            <mat-option [value]="null">All</mat-option>
                            <mat-option *ngFor="let pkg of packages" [value]="pkg.code">
                                {{pkg.code}} - {{pkg.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!-- Organization Filter -->
                    <mat-form-field class="w-64">
                        <mat-label>Organization</mat-label>
                        <mat-select [(ngModel)]="selectedOrganization" (ngModelChange)="onOrganizationChange($event)">
                            <mat-option [value]="null">All</mat-option>
                            <mat-option *ngFor="let org of allOrganizations" [value]="org.id">
                                {{org.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!-- Date Range Filter -->
                    <mat-form-field class="w-96">
                        <mat-label>Date range</mat-label>
                        <mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
                            <input matStartDate formControlName="start" placeholder="Start date" (dateChange)="onDateRangeChange()">
                            <input matEndDate formControlName="end" placeholder="End date" (dateChange)="onDateRangeChange()">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                </div>

                <!-- Loading Spinner -->
                <div class="flex justify-center pt-5" *ngIf="loading">
                    <mat-spinner></mat-spinner>
                </div>

                <!-- Table -->
                <div class="overflow-x-auto">
                    <table mat-table [dataSource]="diligences" matSort 
                           [matSortActive]="currentSort.active"
                           [matSortDirection]="currentSort.direction"
                           (matSortChange)="onSortChange($event)"
                           class="w-full">
                        
                        <!-- Email Column -->
                        <ng-container matColumnDef="Email">
                            <th mat-header-cell *matHeaderCellDef > Email </th>
                            <td mat-cell *matCellDef="let diligence"> {{ diligence.user_email }} </td>
                        </ng-container>

                        <!-- Type Column -->
                        <ng-container matColumnDef="Type">
                            <th mat-header-cell *matHeaderCellDef > Type </th>
                            <td mat-cell *matCellDef="let diligence"> {{ diligence.type }} </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="Name">
                            <th mat-header-cell *matHeaderCellDef > Name </th>
                            <td mat-cell *matCellDef="let diligence"> {{ diligence.name }} </td>
                        </ng-container>

                        <!-- Report Column -->
                        <ng-container matColumnDef="Report">
                            <th mat-header-cell *matHeaderCellDef > Report </th>
                            <td mat-cell *matCellDef="let diligence"> {{ diligence.report }} </td>
                        </ng-container>

                        <!-- Organization Column -->
                        <ng-container matColumnDef="Organization">
                            <th mat-header-cell *matHeaderCellDef> Organization </th>
                            <td mat-cell *matCellDef="let diligence"> {{ diligence.organization_name }} </td>
                        </ng-container>

                        <!-- Update Date Column -->
                        <ng-container matColumnDef="UpdateDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Updated </th>
                            <td mat-cell *matCellDef="let diligence"> {{ diligence.updated_at | date }} </td>
                        </ng-container>

                        <!-- Score Column -->
                        <ng-container matColumnDef="Score">
                            <th mat-header-cell *matHeaderCellDef > Score </th>
                            <td mat-cell *matCellDef="let diligence"> {{ diligence.rep_score }} </td>
                        </ng-container>

                        <!-- Status Column -->
                        <ng-container matColumnDef="Status">
                            <th mat-header-cell *matHeaderCellDef > Status </th>
                            <td mat-cell *matCellDef="let diligence"> {{ diligence.status }} </td>
                        </ng-container>

                        <!-- Phase Column -->
                        <ng-container matColumnDef="Phase">
                            <th mat-header-cell *matHeaderCellDef> Phase </th>
                            <td mat-cell *matCellDef="let diligence"> 
                                <span>
                                    {{ diligence.phase }}
                                </span>
                            </td>
                        </ng-container>

                        <!-- UBO Column -->
                        <ng-container matColumnDef="UBO">
                            <th mat-header-cell *matHeaderCellDef> UBO </th>
                            <td mat-cell *matCellDef="let diligence">
                                <mat-icon *ngIf="diligence.ubo">check</mat-icon>
                            </td>
                        </ng-container>

                        <!-- Actions Column -->
                        <ng-container matColumnDef="Actions">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let diligence"> 
                                <button (click)="openDiligence(diligence.uuid)" class="text-blue-600 hover:text-blue-800">
                                    <mat-icon class="material-symbols-outlined">open_in_new</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>

                    <!-- No Data Message -->
                    <div *ngIf="!loading && diligences.length === 0" class="text-center py-8 text-gray-500">
                        No reports found matching your criteria
                    </div>
                </div>

                <!-- Pagination -->
                <mat-paginator
                    [length]="totalItems"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions"
                    [pageIndex]="currentPage"
                    (page)="onPageChange($event)"
                    aria-label="Select page">
                </mat-paginator>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>

<app-footer></app-footer>
